<template>
  <Layout class="InvitationList">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item>
          <el-input
            v-model="formData.userIdStr"
            clearable
            placeholder="用户ID"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.isPaid"
            placeholder="是否付费"
            clearable
          >
            <el-option
              v-for="(item, index) in payStateList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <div class="overview">
      <i class="fa fa-barcode" />
      <span>{{ invitationInfo.name }}</span>
      ·
      <span>{{ invitationInfo.start }} - {{ invitationInfo.end }}</span>
      ·
      <span>{{ invitationInfo.total }}</span>
    </div>
    <el-table
      ref="table"
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
    >
      <el-table-column type="expand">
        <template #default="{row}">
          <el-form
            :model="row"
            label-position="right"
            class="table-expand"
            label-width="100px"
            :inline="true"
          >
            <el-form-item
              v-for="(item, index) in (row.prizeDetail || [])"
              :key="index"
              :label="`奖励商品${index + 1}`"
            >
              <div
                v-if="item.triggerMode != 3"
                class="detail"
              >
                触发方式 {{ item.triggerMode }} · {{ (triggerModeMap[item.triggerMode] || {}).name || '未知' }}
              </div>
              <div class="detail">
                奖品类型 · {{ (prizeTypeMap[item.prizeType] || {}).name || '未定义' }}
              </div>
              <div class="detail">
                奖品数量 · {{ item.prizeNum }}
              </div>
              <div class="detail">
                发放状态 · <span
                  class="issued"
                  :class="{
                    unissued: item.freedStatus !== 1,
                  }"
                >{{ item.freedStatus === 1 ? '已发放':'未发放' }}</span>
              </div>
              <div class="detail">
                发放时间 · <span>{{ item.freedStatus === 1 ? item.freedTime : '--' }}</span>
              </div>
            </el-form-item>
            <div
              v-for="index in 4"
              :key="'s' + index"
              class="shadow"
            />
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        prop="recordDetail.userIdStr"
        label="用户ID"
      />
      <el-table-column
        prop="recordDetail.isPaid"
        label="是否付费"
      >
        <template #default="{row}">
          {{ (payStateMap[row.recordDetail.isPaid] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column label="邀请码">
        <template #default="{row}">
          {{ row.recordDetail.inviteCode || '/' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="recordDetail.paidCount"
        sortable
        label="订单数量"
        width="100px"
      />
      <el-table-column
        prop="recordDetail.paidNum"
        sortable
        label="累计付费金额($)"
        width="150px"
      >
        <template #default="{row}">
          {{ row.recordDetail.paidNum / DIVISOR.TRUE_AMOUNT }}
        </template>
      </el-table-column>
      <el-table-column
        prop="recordDetail.registeredTime"
        label="注册时间"
      >
        <template #default="{row}">
          {{ dataFomat(row.recordDetail.registeredTime) }}
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import { getInviteRecordList } from '@/api/CouponsAndRedeemCode.js'
import {
  triggerModeMap,
  prizeTypeMap
} from '@/utils/selectOptions.js'

import { DIVISOR } from '@/enum/index.js'
export default {
  name: 'InvitationList',
  data () {
    return {
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      triggerModeMap,
      prizeTypeMap,
      payStateList: [
        { name: '是', value: 1 },
        { name: '否', value: 2 }
      ],
      payStateMap: {
        1: { name: '是', value: 1 },
        2: { name: '否', value: 2 }
      },
      invitationInfo: {},
      DIVISOR
    }
  },
  created () {
    this.invitationInfo = this.$route.query
    this.handleSearch()
  },
  methods: {
    dataFomat (date) {
      if (date === '0000-00-00 00:00:00' || !date) { return '-' }
      return date
    },
    queryDataList () {
      this.listInfo.loading = true
      const activityId = this.$route.query.id
      getInviteRecordList({ ...this.formData, activityId })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    }

  }
}
</script>

<style lang="less">
  .InvitationList{
    .el-table__expanded-cell{
      background-color: #FAFCFD;
      .table-expand{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        label {
          width: 100px;
          color: #99a9bf;
        }
        .el-form-item{
          margin:0 50px 20px 0;
          border-bottom: 1px dashed;
          width: 340px;
          .issued{
            color: green;
          }
          .unissued {
            color: red;
          }
        }
        .shadow{
          margin:0 50px 20px 0;
          width: 340px;
        }
      }
    }
  }
</style>

<style lang="less" scoped>
  .InvitationList{
    .overview {
      background: #eee;
      width: fit-content;
      min-width: 400px;
      height: 50px;
      line-height: 50px;
      border-radius: 0 50px 0 0;
      padding: 0 50px 0 20px;
      font-size: 14px;
      >i {
        color: cadetblue;
        margin-right: 20px;
      }
    }
  }
</style>
